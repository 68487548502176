body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto', sans-serif;
}

.logo {
  padding-left: 24px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

/* CSS for responsive iframe */
/* ========================= */

/* outer wrapper: set max-width & max-height; max-height greater than padding-bottom % will be ineffective and height will = padding-bottom % of max-width */
#Iframe-Master-CC-and-Rs {
  /* max-width: 512px; */
  width: 100%;
  max-height: 100%;
  overflow: hidden;
}

/* inner wrapper: make responsive */
.responsive-wrapper {
  position: relative;
  height: 0;
  /* gets height from padding-bottom */

  /* put following styles (necessary for overflow and scrolling handling on mobile devices) inline in .responsive-wrapper around iframe because not stable in CSS:
    -webkit-overflow-scrolling: touch; overflow: auto; */
}

.responsive-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;
  border: none;
}

/* padding-bottom = h/w as % -- sets aspect ratio */
/* YouTube video aspect ratio */
.responsive-wrapper-wxh-572x612 {
  padding-bottom: 107%;
}

/* general styles */
/* ============== */
.set-border {
  border: 5px inset #4f4f4f;
}

.set-box-shadow {
  -webkit-box-shadow: 4px 4px 14px #4f4f4f;
  -moz-box-shadow: 4px 4px 14px #4f4f4f;
  box-shadow: 4px 4px 14px #4f4f4f;
}

.set-padding {
  padding: 20px;
}

.set-margin {
  margin: 30px;
}

.center-block-horiz {
  margin-left: auto !important;
  margin-right: auto !important;
}

iframe {
  width: 100%;
}

[class^='number-slide'],
[class*=' number-slide'] {
  /* background: grey; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  /* height: 300px;
  max-height: 100vh; */
}

.navigation-wrapper {
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #000;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}

.keen-slider__slide {
  max-height: '100% !important';
  cursor: pointer;
  /* min-width: 1824px !important; */
  /* padding: 0px 5px; */
}

@media screen and (max-width: 598px) {
  .keen-slider .welcomebg {
    height: 230px;
    /* max-height: 230px; */
  }
}

/* @media screen and (max-width: 1124px) and (min-width: 1392px) {
  .keen-slider .welcomebg {
    height: 305px;
    max-height: 305px;
  }
} */

@media screen and (max-width: 1020px) and (min-width: 694px) {
  .welcome {
    /* height: 269px; */
    max-height: 250px !important;
  }
}

@media screen and (max-width: 693px) {
  .welcome {
    /* height: 269px; */
    max-height: 275px !important;
  }
}

.custom-next-arrow,
.custom-prev-arrow {
  color: black; /* Set arrow color */
  font-size: 18px; /* Set arrow size */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.custom-next-arrow {
  right: 10px; /* Adjust the position of the next arrow */
}

.custom-prev-arrow {
  left: 10px; /* Adjust the position of the prev arrow */
}

.slick-dots {
  bottom: 0 !important;
  margin-bottom: 16px !important;
}

.slick-dots li {
  margin: 0 !important;
}

/* navbar css */
.navbar-button {
  margin-left: 20px;
}

.main-menu-active {
  border: 1px solid #3c4764;
  border-radius: 50px;
  background-color: #3c4764;
}

.main-menu {
  border: 1px solid #3c4764;
  border-radius: 50px;
  background-color: #f8f9fa;
}

.nav-link-nav-link {
  padding: 0.5rem !important;
  font-weight: 400;
}

ul.dropdown-menu {
  list-style: none;
}

.navbar-light .navbar-nav .nav-link-nav-link-active {
  color: #fff;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  display: flex;
  align-items: center;
}

.nav-link-nav-link-active {
  padding: 0.5rem !important;
  font-weight: 400;
}

.navbar-light .navbar-nav .active > .nav-link-nav-link,
.navbar-light .navbar-nav .nav-link-nav-link.active,
.navbar-light .navbar-nav .nav-link-nav-link.show,
.navbar-light .navbar-nav .show > .nav-link-nav-link,
.navbar-light .navbar-nav .nav-link-nav-link {
  color: #3c4764;
}

.navbar-nav .nav-link-nav-link {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

li.nav-item.dropdown.main-menu {
  margin-left: 0;
}

.navbar-nav li:hover > ul.dropdown-menu {
  display: block;
  border: none !important;
  margin: 0.1rem 0 0 !important;
  padding: 6px;
  width: max-content;
  border-radius: 6px;
  box-shadow: 1px 1px 12px lightgray;
}

.dropdown-submenu {
  position: relative;
  cursor: pointer;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

/* rotate caret on hover */
.dropdown-menu > li > a:hover:after {
  text-decoration: none;
  /* transform: rotate(90deg); */
}

.dropdown-item {
  line-height: 1.467;
  font-size: 0.9375rem;
  font-family: 'Public Sans', sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  color: rgba(47, 43, 61, 0.78);
  display: flex;
  align-items: center;
  margin: 4px 0;
  padding: 0.4rem 2rem 0.4rem 0.5rem !important;
  text-decoration: none;
}

/* Default dropdown icon (arrow) for all dropdowns */
.dropdown-toggle::after {
  display: none;
  /* Hide the default dropdown icon */
  border-top: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
}

/* Add greater-than sign for dropdown-submenu */
.dropdown-submenu > .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.5em;
  vertical-align: middle;
  content: none;
  font-size: 1.3rem;
  /* color: #6c757d; */
  position: absolute;
  right: 0;
  margin-right: 6px;
}

.dropdown-menu > .dropdown-toggle::after {
  content: none;
}

.sub-dropdown-span {
  position: absolute;
  right: 0;
  top: 10px;
}

.dropdown-menu > li > a:hover,
.dropdown-submenu > a:hover {
  background-color: #faeced;
  color: #3c4764;
  border-radius: 4px;
}

.dropdown-item:focus {
  background-color: #3c4764 !important;
  color: white !important;
  border-radius: 4px;
}

.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #3c4764;
  border-radius: 4px;
}

.navbar-light .navbar-nav .nav-link-nav-link:focus,
.navbar-light .navbar-nav .nav-link-nav-link:hover {
  color: #3c4764;
}

/* Submenu-specific styles */
.navbar-nav .dropdown-submenu > .dropdown-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 0;
  border: none;
  background-color: #fff;
}

.navbar-nav .dropdown-submenu.show > .dropdown-menu {
  display: block;
}

.navbar-container {
  display: flex;
  flex-direction: row; /* Items in a horizontal row */
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
}

.navbar-nav {
  display: flex;
  flex-direction: row; /* Horizontal alignment of nav items */
  flex-wrap: wrap; /* Allow menus to wrap to the next line */
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 14px;
}

.nav-item {
  margin: 0; /* Spacing between items */
}

.nav-item .nav-link-nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
}

.navbar-container .dropdown-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-top: 5px;
  display: none;
}

/* .navbar-container .dropdown:hover .dropdown-menu {
    display: block;
  } */

/* Tablet-specific styles */
@media (max-width: 867) {
  .navbar-container {
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
  }

  .nav-item {
    margin: 5px 10px; /* Adjust spacing for tablets */
  }

  .navbar-nav {
    gap: 10px;
  }
}
